import type { APIMethodParams } from '@medizzy/api';
import { z } from 'zod';

export const signInRequestSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(1, { message: 'Password must be at least 8 characters long' }),
});

export const signInResponseSchema = z.object({
  alltimeActivityPoints: z.number(),
  alltimeRankingPoints: z.number(),
  alltimeRankingPosition: z.number().optional(),
  avatarUrl: z.string().optional(),
  biography: z.string().optional(),
  email: z.string().email(),
  followedByMe: z.boolean().optional(),
  favouritePostsCount: z.number().optional(),
  followedSections: z.unknown().array(),
  followedSectionsCount: z.number(),
  followedTagsCount: z.number().optional(),
  followedUsersCount: z.number().optional(),
  followersCount: z.number().optional(),
  id: z.number(),
  isVerified: z.boolean(),
  level: z.object({
    level: z.string(),
    score: z.number(),
  }),
  name: z.string(),
  numberOfPosts: z.number(),
  occupation: z.string().optional(),
  occupationEncoded: z.string().optional(),
  payments: z
    .object({
      learning: z.object({
        active: z.boolean(),
        period: z.string().nullable(),
        expirationDate: z.string().nullable(),
        trialActive: z.boolean().optional(),
        deactivatedBy: z.number().or(z.string()).optional(),
        trial: z.object({
          learningMaterialsLeft: z.number(),
          learningMaterialsLimit: z.number(),
          learningMaterialsConsumed: z.number(),
        }),
      }),
      stripe: z
        .object({
          customerId: z.string(),
        })
        .optional(),
    })
    .optional(),
  refreshToken: z.string(),
  roles: z.array(
    z.enum([
      'ROLE_ADMIN',
      'ROLE_NOTIFICATION_ADMIN',
      'ROLE_PARTNER_ADMIN',
      'ROLE_MODERATOR',
      'ROLE_REVIEWER',
    ]),
  ),
  settings: z.object({
    excludedCategories: z.array(z.number()),
    gdprOtherUsersAdsAllowed: z.boolean().optional().nullable(),
    gdprMedizzyAdsPreferencesSharingAllowed: z.boolean().optional().nullable(),
    gdprOutsideActivityAdsAllowed: z.boolean().optional().nullable(),
    gdprPushAllowed: z.boolean().optional().nullable(),
  }),
  token: z.string(),
  tokenExpireDate: z.string().datetime(),
  university: z.string().optional(),
  username: z.string(),
  verificationCheck: z.boolean(),
  boardingCompleted: z.boolean(),
});

export type SignInParams = z.infer<typeof signInRequestSchema>;
export type SignInResponse = z.infer<typeof signInResponseSchema>;

export function signIn({ client, params }: APIMethodParams<SignInParams>) {
  return client
    .url('/login')
    .post(signInRequestSchema.parse(params))
    .valid(signInResponseSchema);
}
